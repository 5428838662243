import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'secure', loadChildren: './view/secure/secure.module#SecureModule' }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, {
    // Tell the router to use the hash instead of HTML5 pushstate.
    useHash: true,

    // These aren't necessary for this demo - they are just here to provide
    // a more natural experience and test harness.
    scrollPositionRestoration: "enabled",
    anchorScrolling: "enabled",
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
