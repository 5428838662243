import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GestureConfig } from '@angular/material';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import * as Hammer from "hammerjs";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Extend `GestureConfig` instead of `HammerGestureConfig` as we want to preserve
// the material design module's gesture config for the interactions to work..
export class HammerConfig extends GestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
